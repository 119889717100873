.Navbar{
    height: 56px;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,.95);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    box-shadow: 0px 0px 10px -7px rgba(0,0,0,.6);
    z-index:90;
}

.Navbar p {
    font-family: 'Work Sans', sans-serif;
    font-size: x-large;
    color: #ea5455;
    cursor: pointer;
}

.Navbar nav {
    height: 100%;
}


@media (max-width: 546px) {
    .DesktopOnly {
        display: none;
    }
}