#Projects {
    position: relative;
    display: block;
    height: 100%;
    min-height: 100vh;
    margin: auto;
    margin-top: 70px;
    margin-bottom: 30px;
}

.Links {
    margin-top: 10px;
    margin-right: 10px;
}

.Gradient {
    font-family: 'Open Sans';
    background: linear-gradient(120deg, #ff7300, #ffc402);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}