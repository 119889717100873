.Footer {
    margin-top: 80px;
    height: 100px;
    background-color: rgba(0,0,0,.01);
    box-shadow: 0px 0px 10px -7px rgba(0,0,0,.6);
}

.Footer p {
    display: inline-block;
    font-family: 'Work Sans';
    margin-top: 40px;
    background: linear-gradient(120deg, #ff5e00, #ff9e02);
    font-weight: 300;
    font-size: 1.3rem;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Footer p a {
    text-decoration: underline;
    text-decoration-color: rgba(0,0,0,0.3);
}

@media (max-width:620px) {
    .Footer p {
        font-size: 1rem;
    }
}

@media (max-width:420px) {
    .Footer p {
        font-size: 0.7rem;
    }
}