#About {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 60vh;
    padding-bottom: 70px;
}



#About .Image {
    display: inline-block;
    width: 250px;
    height: 250px;
    margin-right: 80px;
    margin-bottom: 20px;
    border-radius: 120px;
}

#About .Outer {
    margin: 0 auto;
    width: calc(930px - 10vh)
}


#About .Info {
    display: inline-block;
    margin-top: 70px;
    width: 450px;
    height: 420px;
    border-radius: 50px;
    background-color: white;
    box-shadow: 0px 0px 30px 0px rgba(70, 70, 70, 0.1);
}

#About .Text {
    padding: 0 18px;
    font-size: 1.4rem;
    margin-top: 40px;
    font-weight: 300;
    font-family: 'Helvetica';
}

#About .Text span {
    color: #ea5455;
}

#About .Resume {
    display: inline-block;
    height: 45px;
    margin: auto;
    margin-top: 10px;
    margin-right: 10px;
}


@media (max-width:1050px) {
    #About .Text {
        font-size: 1.3rem;
    }
    #About .Info {
        width: 450px;
        height: 390px;
    }
}


@media (max-width:800px) {
    #About .Image {
        display: block;
        float: none;
        margin: 40px auto;
        text-align: center;
    }

    #About .Info {
        float: none;
        display: block;
        margin: auto;
        width: 450px;
        height: 385px;
    }

    #About .Text {
        padding-top: 50px;
        font-size: 1.2rem;
        padding-left: 20px;
        padding-right: 20px;
    }

    #About .Outer {
        margin: 0 auto;
        width: 100%;
    }


}


@media (max-width:499px) {
    #About .Image {
        width: 175px;
        height: 175px;
    }

    #About .Info {
        width: 300px;
        height: 385px;
        border-radius: 20px;
    }
    #About .Text {
        font-size: 1rem;
        padding-left: 15px;
        padding-right: 15px;
    }
    #About .Outer {
        width: 95%;
    }



}

@media (max-width:320px) {
    #About .Image {
        width: 150px;
        height: 150px;
    }

    #About .Info {
        width: 250px;
        height: 320px;
        border-radius: 10px;
    }
    #About .Text {
        padding: 0 20px;
        padding-top: 20px;
        font-size: 0.8rem;
    }

}

