#Experiences {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    min-height: 100vh;
    padding-bottom: 30px;
}

#Experiences .Exp {
    width: 1000px;
    margin: 0;
    display: inline-block;
    margin-top: 70px;
    text-align: left;
    background-color: white;
    box-shadow: 0px 0px 30px 0px rgba(70, 70, 70, 0.1);
    border-radius: 40px;
}

#Experiences .DescBox {
    width: 900px;
    margin: 0;
    margin-top: 30px;
    margin-left: 60px;
    font-size: 1.4rem;
    padding-bottom: 10px;
}


.DemoTitle {
    display: block;
    margin-left: 50px;
    font-size: 1.8rem;
    font-family: 'Work Sans';
    margin-top: 10px;

}
#Experiences .Company {
    position: relative;
    float: right;
    margin-right: 250px;
    
}
#Experiences .Company img {
    position: absolute;
    bottom: 10px;
    height: 59px;
    width: 170px;
}

.zynga {
    border-radius: 20px;
}

@media (max-width:1050px) {
    #Experiences .Exp {
        width: 750px;
    }

    #Experiences .DescBox {
        width: 650px;
        margin-left: 50px;
        font-size: 1.3rem;
    }
    .DemoTitle {
        margin-left: 37px;
        font-size: 1.7rem;
    }

}

@media (max-width:800px) {
    #Experiences .Exp {
        width: 600px;
    }
    
    #Experiences .DescBox {
        width: 500px;
        margin-left: 55px;
        font-size: 1.2rem;
    }
    .DemoTitle {
        margin-left: 42px;
        font-size: 1.6rem;
    }
    #Experiences .Company {
        margin-right: 130px;
        
    }
    #Experiences .Company img {
        bottom: 20px;
        height: 40px;
        width: 100px;
    }

}

@media (max-width:620px) {
    #Experiences .Exp {
        width: 450px;
    }
    
    #Experiences .DescBox {
        width: 370px;
        margin-left: 45px;
        font-size: 1rem;
    }
    .DemoTitle {
        margin-left: 32px;
        font-size: 1.3rem;
    }
    #Experiences .Company img {
        height: 70px;
        width: 190px;
        margin: auto;
        margin-top: 40px;
        position: relative;
    }

}

@media (max-width:475px) {
    #Experiences .Exp {
        margin: inherit 0;
        width: 350px;
        text-align: center;
    }
    
    #Experiences .DescBox {
        width: 300px;
        font-size: 0.9rem;
        margin-left: 25px;
        margin-top: 15px;
    }

    .DemoTitle {
        margin-left: 0;
        margin-top: 15px;
        font-size: 1.3rem;
    }

    .Date {
        display: block;
        margin-left: 0;
        text-align: center;
    }
    #Experiences .Company {
        float: none;
        margin: 0;
        margin-top: 20px;
    }
    #Experiences .Company img {
        height: 50px;
        width: 150px;
        bottom: 0;
        margin-top: 0;
        position: relative;
    }

}


@media (max-width:374px) {
    #Experiences .Exp {
        width: 100%;
        border-radius: 0px;
    }
    #Experiences .DescBox {
        width: 100%;
        font-size: 0.9rem;
        margin-left: 0px;
    }
}