.Description {
    width: 900px;
    margin: 0;
    margin-top: 30px;
    margin-left: 60px;
    font-size: 1.4rem;
    padding-bottom: 10px;
    
}

@media (max-width:1050px) {
    .Description {
        width: 650px;
        margin-left: 50px;
        font-size: 1.3rem;
    }
}


@media (max-width:800px) {
    .Description {
        width: 500px;
        margin-left: 55px;
        font-size: 1.2rem;
    }
}

@media (max-width:620px) {
    .Description {
        width: 370px;
        margin-left: 45px;
        font-size: 1rem;
    }
}

@media (max-width:475px) {
    .Description {
        width: 300px;
        font-size: 0.9rem;
        margin-left: 25px;
        margin-top: 15px;
    }
}


@media (max-width:374px) {
    .Description {
        width: 100%;
        margin-left: 0px;
    }
}