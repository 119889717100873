.Item {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    
}

.Item a {
    color: #ea5455;
    font-weight: 500;
    text-decoration: none;
    font-size: x-large;
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    display: block;

}

.Item a:hover,
.Item a:active,
.Item a.active {
    background-color: rgba(0,0,0,0.05);
}



@media (min-width:500px) {

    .Item {
        margin:0;
        display: flex;
        height: 70%;
        width: auto;
        align-items: center;
        

    }

    .Item a {
        color: black;
        height: 100%;
        padding: 10px 10px;
        border-bottom: 4px solid transparent;
        font-size: 1.1rem;
    }

    .Item a:hover,
    .Item a:active,
    .Item a.active {
        background-color: rgba(0,0,0,0.05);
        border-radius: 100px;
        font-weight: 500;
        cursor: pointer;

    }
}

@media (max-width: 325px) {
    .Item a{
        font-size: 1.1rem;
        padding: 5px 0;
    }
}