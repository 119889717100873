.SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    border-right: 1px solid rgba(0,0,0,0.05);
    transition: transform 0.3s ease-out;
}

.SideDrawer img {
    width: 170px;
    height: 170px;
    z-index: inherit;
    margin-bottom: 35px;
    border-radius: 100px;
}

.SideDrawer p {
    display: block;
    position: absolute;
    margin: 0;
    width: 87%;
    text-align: center;
    bottom: 5px;
}

.Open {
    transform: translateX(0)
}

.Close {
    transform: translateX(-100%);
}

@media (min-width: 500px) {
    .SideDrawer {
        display: none;
    }
}

@media (max-width: 325px) {
    .SideDrawer img {
        width: 140px;
        height: 140px;
        margin-bottom: 20px;
    }
}





